const idPortalApi = "5bad353694d5b677916235bad05ad80d8912bbe0";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  //config botões e imagens
  dnsPortal: "cmsaoroberto.ma.gov.br",
  banner_defalt: true, //se não quiser o banner transparencia azul padrao

  NomePortal: "São Roberto",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 6, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://cmsaoroberto.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13890.072511857!2d-44.9924296!3d-5.0212348!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x92ccd562a6d867f3%3A0x326a1f256351e2cc!2sS%C3%A3o%20Roberto%2C%20MA%2C%2065758-000!5e1!3m2!1spt-BR!2sbr!4v1731369689385!5m2!1spt-BR!2sbr`,
};
